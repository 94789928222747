@tailwind base;
@tailwind components;
@tailwind utilities;

/*********************************/
/* Global Styles */
/*********************************/
/* Global Defaults in src/index.css */
body {
  @apply font-sans text-body text-primary-600; /* Default font, size, and color for all text */
}

p {
  @apply font-sans text-body text-primary-600; /* Body text styling */
}

small {
  @apply font-serif text-small text-primary-500; /* Smaller text, slightly lighter */
}

h1, h2, h3, h4, h5, h6 {
  /* Headings have their own sizes (header, display, title, etc.) */
  @apply font-serif text-primary-800;
}

a {
  @apply text-primary-600;
}

button {
  @apply bg-transparent border-0 p-0 m-0 text-inherit; /* Reset default button styles */
}

/*********************************/
/*Link Styles*/
/*********************************/
/* Subtle Link: Preserve text color, underline on hover */
.link-subtle {
  @apply hover:underline;
}

/* Important Link: Amber background highlight on hover */
.link-important {
  @apply underline hover:bg-accent-100 hover:text-primary-600 rounded-sm transition-colors;
}

/* Additional Materials Link */
.link-materials {
  @apply text-primary-100 hover:text-accent-300;
}

/* Prominent Link: Amber underline on hover, using Tailwind's accent color */
.link-prominent {
  @apply hover:underline hover:decoration-accent-400 hover:decoration-2 hover:underline-offset-4;
}